import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSocket } from "../utils/query";

import Chat from "../components/Chat";
import "../components/Chat.css";
import Countdown from "../components/Countdown";
import PlayerList from "../components/PlayerList";
import "./DrawingCanvas.css";
import "../components/PlayerList.css";

function DrawingCanvas() {
  const canvasRef = useRef(null);
  const [color, setColor] = useState("black");
  const [lineWidth, setLineWidth] = useState(5);
  const [isErasing, setIsErasing] = useState(false);
  const [eraseWidth, setEraseWidth] = useState(10);

  const socket = getSocket();
  const navigate = useNavigate();
  const { roomId } = useParams();
  const [isOwner, setIsOwner] = useState(false);
  const [isMyTurn, setIsMyTurn] = useState(false);

  const [words, setWords] = useState([]);
  const [showWords, setShowWords] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");
  const [countdown, setCountdown] = useState(undefined);
  const [players, setPlayers] = useState([]);

  const colors = ["black", "red", "blue", "green", "yellow", "orange", "purple", "cyan", "pink", "brown", "grey"];

  useEffect(() => {
    const canvas = canvasRef.current; //reference a elemento html
    const ctx = canvas.getContext("2d");
    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;

    const onMouseDown = (event) => {
      if (!isMyTurn) return;
      event.preventDefault();
      isDrawing = true;
      lastX = event.offsetX;
      lastY = event.offsetY;
    };

    const onMouseMove = (event) => {
      if (!isMyTurn || !isDrawing) return;
      ctx.strokeStyle = isErasing ? "white" : color;
      ctx.lineWidth = isErasing ? eraseWidth : lineWidth;
      ctx.beginPath();
      ctx.moveTo(lastX, lastY);
      ctx.lineTo(event.offsetX, event.offsetY);
      socket.emit("stroke", {
        style: ctx.strokeStyle,
        lineWidth: ctx.lineWidth,
        x: event.offsetX,
        y: event.offsetY,
        lastX,
        lastY,
      });
      ctx.stroke();
      lastX = event.offsetX;
      lastY = event.offsetY;
    };

    const onMouseUp = () => (isDrawing = false);
    const onMouseLeave = () => (isDrawing = false);
    const onMouseEnter = (event) => {
      if (event.buttons === 1 && isMyTurn) {
        isDrawing = true;
        lastX = event.offsetX;
        lastY = event.offsetY;
      }
    };

    canvas.addEventListener("mousedown", onMouseDown);
    canvas.addEventListener("mousemove", onMouseMove);
    canvas.addEventListener("mouseup", onMouseUp);
    canvas.addEventListener("mouseleave", onMouseLeave);
    canvas.addEventListener("mouseenter", onMouseEnter);

    socket.on("stroke", ({ style, lineWidth, x, y, lastX, lastY }) => {
      ctx.strokeStyle = style;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(lastX, lastY);
      ctx.lineTo(x, y);
      ctx.stroke();
    });

    socket.on("resetCanvas", () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    });

    return () => {
      socket.off("stroke");
      socket.off("resetCanvas");
      canvas.removeEventListener("mousedown", onMouseDown);
      canvas.removeEventListener("mousemove", onMouseMove);
      canvas.removeEventListener("mouseup", onMouseUp);
      canvas.removeEventListener("mouseleave", onMouseLeave);
      canvas.removeEventListener("mouseenter", onMouseEnter);
    };
  }, [color, lineWidth, isErasing, eraseWidth, isMyTurn, socket]);

  useEffect(() => {
    socket.emit("joinRoom", roomId);
    function reconnect(){
      socket.emit("joinRoom", roomId);
    }
    socket.on("reconnect", reconnect);
    socket.on("infoRoom", ({ isOwner, myTurn, word, turnEndTime, players }) => {
      setIsOwner(isOwner);
      setIsMyTurn(myTurn);
      setSelectedWord(word || "Inizia il gioco per scegliere una parola");
      setCountdown(Date.parse(turnEndTime));
      setPlayers(players);
    });

    socket.on("error", (error) => {
      alert(error);
      navigate("/");
    });

    socket.on("chooseWord", (words) => {
      setShowWords(true);
      setWords(words);
    });

    socket.on("win", ({ username, word, score }) => {
      alert(`${username} ha indovinato la parola: ${word} - Punteggio: ${score}`);
    });

    return () => {
      socket.off("error");
      socket.off("reconnect", reconnect);
      socket.emit("leaveRoom");
    };
  }, [roomId, navigate, socket]);

  const handleWordChosen = (word) => {
    socket.emit("wordChosen", word);
    setShowWords(false);
  };

  const handleResetButton = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    socket.emit("clearCanvas");
  };

  const handleStartButton = () => {
    socket.emit("startGame");
  };

  return (
    <div className="page">
      <div className="popup" style={{ display: showWords ? "block" : "none" }}>
        <h3>Scegli la parola</h3>
        {words.map((word) => (
          <button className="popup-button" key={word} onClick={() => handleWordChosen(word)}>
            {word}
          </button>
        ))}
      </div>
      <div className="canvas-container">
        <h3>{isMyTurn ? `Parola da disegnare: ${selectedWord}` : `Indovina: ${selectedWord}`}</h3>
        <Countdown date={countdown} />
        <canvas ref={canvasRef} width={800} height={500}></canvas>
        <div className="button-group">
          <button className={!isErasing ? "active" : ""} onClick={() => setIsErasing(false)}>
            Matita
          </button>
          <button className={isErasing ? "active" : ""} onClick={() => setIsErasing(true)}>
            Gomma
          </button>
        </div>


        {/* Controls */}
        <div style={{ marginTop: "10px" }}>
          {/* Color Palette */}
          <div style={{ display: "flex", gap: "10px" }}>
            {colors.map((c) => (
              <div
                key={c}
                onClick={() => setColor(c)}
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: c,
                  borderRadius: "50%",
                  border: color === c ? "3px solid blue" : "2px solid gray",
                  cursor: "pointer",
                }}
              ></div>
            ))}
          </div>
          </div>

          <div className="slider-group">
            <label htmlFor="brush-size">Dimensioni matita:</label>
            <input
              id="brush-size"
              type="range"
              min="1"
              max="50"
              value={lineWidth}
              onChange={(e) => setLineWidth(e.target.value)}
              disabled={isErasing || !isMyTurn}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="eraser-size">Dimensioni gomma:</label>
            <input
              id="eraser-size"
              type="range"
              min="5"
              max="50"
              value={eraseWidth}
              onChange={(e) => setEraseWidth(e.target.value)}
              disabled={!isErasing || !isMyTurn}
            />
          </div>
          <div className="reset-button-container">
            {isMyTurn && <button id="reset-button" onClick={handleResetButton}>Reset Canvas</button>}
            {isOwner && <button id="start-game-button" onClick={handleStartButton}>Start Game</button>}
          </div>
          <PlayerList players={players} />
        </div>
        <Chat />
      </div>
      );
}

      export default DrawingCanvas;
