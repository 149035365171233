import { useNavigate } from "react-router-dom";
import { isLoggedIn, login, register } from "../utils/query";
import { useEffect, useState } from "react";
import "./home.css";
export default function Sign(){
    const [ signUp, setSignUp ] = useState(false);

    const navigator = useNavigate();
    function handleLogin(e)  {
        e.preventDefault();
        let form = new FormData(e.target);
        let {username, password} = Object.fromEntries(form);
        let sign = login;
        if (signUp) {
            sign = register;
        }

        sign(username,  password).then( () => {
            navigator("/");
        }).catch(() => {
            alert("Login errato")
        })
    }

    useEffect(() => {
        isLoggedIn().then((isLogged) => {
          if (isLogged) {
            navigator('/')
          }
        })
      });

    return (<div className="page center">
        <h1>DrawingCanvas</h1> 
            <div className="login">
                
                <h2>{signUp ? "Sign Up" : "Login"}</h2>
                <form onSubmit={handleLogin} id="userinfo"> 
                    <label>Username:</label>
                    <input type="text" name="username" required/>
                    <br/>
                    <label>Password:</label>
                    <input type="password" name="password" required/>
                    <button type="submit" >{signUp ? "Sign Up" : "Login"}</button>
                </form>
            {
                !signUp && <a onClick={() => setSignUp(true)} href="#">Non hai un account? Iscriviti</a>
            }
            {
                signUp && <a onClick={() => setSignUp(false)} href="#">Hai già un account? Accedi</a>
            }
        </div></div>
    )
}