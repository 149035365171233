import { useNavigate, useParams } from "react-router-dom";
import { getSocket } from "../utils/query";
import { useEffect, useState } from "react";
import "./Chat.css";

export default function Chat() {
    const { roomId } = useParams();
    const  [messages, setMessages] = useState([]);//creo array di messaggi
    const  [newMessage, setNewMessage] = useState('');
    const socket = getSocket();

    useEffect(() => { //array di messages
        socket.on("message", (message) => {
            
            setMessages([...messages, message]);
        });

        socket.on("playerJoin", (player) => {
            let message = {
                username: < span style={{color:"green"}}>{player}</span>,
                message: < span style={{color:"green"}}> E' entrato nella stanza</span>,
            }
            setMessages([...messages, message]);
        })
//vedi socket backend
        socket.on("playerLeave", (player) => {
            let message = {
                username: < span style={{color:"red"}}>{player}</span>,
                message: < span style={{color:"red"}}> Ha lasciato la stanza </span>,
            }
            setMessages([...messages, message]);
        })
        return  () => {
            socket.off("message");
            socket.off("playerJoin");
            socket.off("playerLeave");
        }        
    }, [messages])

    


    function handleSend() {
        socket.emit("message", newMessage);
        setNewMessage('');
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSend(); // Invia il messaggio se è stato premuto "Enter"
        }
    }; 
    
    return (
        <div className="chat-container">
    <div className="chat">
        <h1>{roomId}</h1>
       
        <ul>
       
            {messages.map(({ message, username }, index) => (
                <li key={index}><em>{username}</em>: {message}</li>
            ))}
        </ul>

        {/* Input di testo con gestione del tasto Enter */}
        
        <div className="input-button-container">
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                    <button onClick={handleSend}>Invia</button>
                </div>
            </div>
        </div>
    );
}

