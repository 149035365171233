import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import DrawingCanvas from "./pages/DrawingCanvas";
import Home from "./pages/Home";
import Login from './pages/Login';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { isLoggedIn } from './utils/query';

//Autheticated è un componente wrapper che verifica se l'utente è loggato, reindirizzandolo alla pagina di login se non è autenticato.
function Autheticated({ children }) {
  const navigator = useNavigate();

  useEffect(() => {
    isLoggedIn().then((isLogged) => {
      if (!isLogged) {
        navigator('/login')
      }
    })
  });

  return (
    <>
      {children } {/*il children è /home ,/drawingcanvas*/}
    </>
  )
}

const router = createBrowserRouter([
  { path: '/', element: <Autheticated><Home /></Autheticated> },
  { path: '/login', element: <Login /> },
  { path: '/drawing/:roomId', element: <Autheticated><DrawingCanvas /></Autheticated> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);