import React, { useEffect, useState } from 'react';
import './Countdown.css';

export default function Countdown({ date }) {
  const [timeLeft, setTimeLeft] = useState(NaN);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(() => {
        const difference = Math.floor((date - new Date())/1000);
        return difference;
      });
    }, 1000);

    return () => clearInterval(timer); // Pulisce il timer quando il componente si smonta
  }, [date]);

  return (
    <div className="countdown-container">
      {timeLeft > 0 ? (
        <span className="countdown-text">{timeLeft}</span>
      ) : (
        <span className="countdown-ended">Turno terminato!</span>
      )}
    </div>
  );
}

