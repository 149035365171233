import { useEffect, useState } from "react"
import { getUsername, logout } from "../utils/query";
import { useNavigate } from "react-router-dom";
import "./home.css";
export default function Home() {
    const [username, setUsername] = useState("");
    const navigator = useNavigate();
    const [roomId, setRoomId] = useState("");

    useEffect(() => {
        getUsername().then((user) => {
            setUsername(user);
        })
    })

    function handleLogout() {
        logout().then(() => {
            navigator('/login')
        })
    }
    function handleCrea() {
        navigator(`/drawing/${roomId}`)

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleCrea(); // Invia il messaggio se è stato premuto "Enter"
        }
    }
    return (<div className="page center">
    <h1>DrawingCanvas</h1> 
        <div className="home">  
            <h1>Home</h1>
            <h2>Benvenuto, {username}</h2>
            <button onClick={handleLogout}>Logout</button>
            <div>
                <h2>Crea o Unisciti a una stanza</h2>
                <input
                    onKeyDown={(e) => handleKeyPress(e)}
                    type="text"
                    placeholder="Room ID"
                    value={roomId}
                    onChange={(e) => setRoomId(e.target.value)}
                />
                <button  disabled= {roomId.length===0} onClick={handleCrea}>JOIN ROOM</button>

            </div>
        </div></div>

    )
}