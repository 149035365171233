import { io } from "socket.io-client"


let development = false;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    development = true;
}
let BASE_URL = development ? "http://localhost:3001/" : "/api/";


let socket = io.connect(development ? BASE_URL : "", { path: development ? undefined : '/api/socket.io', withCredentials: true });

export function getSocket() {
    return socket;
}

function get(url) {
    return fetch(`${BASE_URL}${url}`, { method: 'GET', credentials: "include" }).then((data) => data.json());
}

export function getUsername() {
    return  get("user/me").then((data) => data.username);
}

export function isLoggedIn() {
   return  getUsername().then((username) => !!username); //undefined-true-false
}
//modello post
function post(url,  data) {
    return fetch(`${BASE_URL}${url}`, {  method: 'POST', credentials: "include", body: JSON.stringify(data), headers: {  'Content-Type': 'application/json' } })
        .then( (res) => { if (!res.ok)  { throw new Error(res.statusText); } else { return res.json(); }  } );

}

// Richieste al server per login, registrazione, logout
export function login(username, password) {
    return post("user/login", { username, password });
}

export function register(username, password) {
    return post("user/register", { username, password });
}
export function logout()  {
   return post("user/logout")
}
